<div class="drawer">
    <ebay-lightbox-dialog
        a11y-close-text=input.a11yCloseText 
        a11y-minimize-text=input.a11yMinimizeText 
        a11y-maximize-text=input.a11yMaximizeText 
        size="wide"
        open=input.open
        on-close('emit', 'close')>
        <@header>
            <${input.header}/>
        </@header>
            <${input.body}/>
        <@footer>
            <${input.footer}/>
        </@footer>
    </ebay-lightbox-dialog>
</div>