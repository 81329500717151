const pubsub = require('raptor-pubsub');
const { get } = require('@ebay/retriever');
const { googlemapConfig } = require('../../../utils/mapConfig');
const { doRedirection, doRedirectionByUrl } = require('../../utils/redirectionUtil');
const { setElementFocus } = require('../../utils/accessibilityUtil');
const { getShopactionsCsrfToken } = require('../../utils/csrfUtil');

module.exports = class {
    onCreate(input) {
        input.mapConfig = googlemapConfig();
        this.channelId = input.channelId || '';
        this.pageMapping = {
            'VAS_INTRO': 'introduction',
            'VAS_SPOKE': 'installer',
            'VAS_INTERSTITIAL': 'installer',
            'VAS_APPOINTMENT_SCHEDULE': 'appointment',
            'VAS_SUMMARY': 'summary'
        };
        this.state = {
            showLoading: false,
            visiblePage: 'introduction',
            introduction: null,
            installer: null,
            appointment: null,
            summary: null,
            showError: false,
            selectedInstaller: null,
            selectedSlot: null,
            selectedDate: null,
        };
        this.targetElId = get(input, 'target', '');
        // sets the model and visiblePage based on the data
        this.handlePageMapping(input.model);
        this.trksid = get(input, 'modelConfig.trksid', '');
        this.isSpoke = get(input, 'isSpoke', true);
        this.isMobile = get(input, 'options.global.isMobile', false);
        this.proxyUpdateSummary = this.updateSummary.bind(this);
        this.proxyUpdateCustomProperties = this.updateCustomProperties.bind(this);
    }

    onMount() {
        // this.handleAccessibilityFocus();
        this.vasContainerElem = document.getElementById('vas-container');
        if (this.isMobile) {
            this.updateCustomProperties();
            // We listen to the resize event
            window.addEventListener('resize', this.proxyUpdateCustomProperties);
        }
        window.addEventListener('pageshow', this.proxyUpdateSummary);
    }

    onDestroy() {
        window.removeEventListener('pageshow', this.proxyUpdateSummary);
        if (this.isMobile) {
            window.removeEventListener('resize', this.proxyUpdateCustomProperties);
        }
    }

    updateCustomProperties() {
        const vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--pivh', `${vh}px`);
    }

    updateSummary(event) {
        // Check if the page was restored from the cache (persisted state).
        if (event.persisted) {
          // Only proceed if `showLoading` state is true.
          if (this.state && this.state.showLoading) {
            // Using `setTimeout` with 0 delay to defer the action until after the current event loop tick.
            setTimeout(() => {
              // Refresh the page by setting `location.href` to the current URL.
              window.location.href = window.location.href;
            }, 0); // Explicitly setting the delay to 0 for clarity.
          }
        }
    }

    handleInstallerClick(action, selectedInstaller) {
        if(selectedInstaller) {
            this.state.selectedInstaller = selectedInstaller;
            const vasValues = get(selectedInstaller, 'vasValues',{});
            this.handleNextAction(action, Object.assign(selectedInstaller.additionalParamKeyValues,{
                hubPostalCode: vasValues.hubPostalCode || '',
                hubCountryCode: vasValues.country || '',
                distanceAway: vasValues.distanceAway || ''
            }));
        } else {
            this.state.showError = true;
        }
    }

    installerUpdate(response) {
        this.state.installer = response || {};
    }

    handleSchedulingClick(action, selectedParams, selectedDate, selectedSlot) {
        this.state.selectedDate = selectedDate;
        this.state.selectedSlot = selectedSlot;
        this.handleNextAction(action, selectedParams);
    }

    handleSecLabelClicked(secLable) {
        this.publishTracking(get(secLable, 'action.trackingList', []));
    }

    handleFinalAction(action, selectedParams) {
        this.handleNextAction(action, selectedParams);
    }

    handleNextAction(action, selectedParams) {
        if (action) {
            this.state.showLoading = true;

            this.publishTracking(get(action, 'trackingList', []));
            // Abort controller for timeout
            const controller = new AbortController();
            const id = setTimeout(() => {
                controller.abort();
            }, (action.timeout || 6000));

            // Input data for the request
            let url = action.url || action.URL || '';
            const name = action.name || '';
            const params = action.params || {};
            const uxComponent = params.supportedUxComponents;

            // Visible page
            const visiblePage = this.state.visiblePage || '';

            // VASSELECTION PARAMS
            Object.assign(params, {
                vasSelectionParams: get(this, `state[${visiblePage}].VAS_DATA.dataItems`, [])
            });
            
            if(selectedParams && Object.keys(selectedParams).length) {
                const vasSelectionParams = params.vasSelectionParams || [];
                vasSelectionParams.forEach(vasParam => {
                    if ((vasParam.serviceType === selectedParams.serviceType && vasParam.serviceValue === selectedParams.serviceValue)) {
                        Object.assign(vasParam, {
                            ...selectedParams
                        });
                    }
                });
            }

            if(uxComponent && !Array.isArray(uxComponent)) {
                params.supportedUxComponents = [uxComponent];
            }

            if(this.state.visiblePage === 'introduction') {
                this.state.selectedInstaller = null;
            } else if(this.state.visiblePage === 'installer') {
                this.state.selectedDate = null;
                this.state.selectedSlot = null;
            }

            if(name === 'SHOPACTIONS') {
                url = `${url  }srt=${getShopactionsCsrfToken(action, get(this.input, 'options.global',{}))}`;
            }

            fetch(url, {
                signal: controller.signal,
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
                body: JSON.stringify(params)
            }).then(response => {
                this.state.showError = !response.ok;
                return response.json();
            }).then(response => {
                if (response && Object.keys(response).length && !response.error) {
                    this.state.showError = false;
                    if(name === 'PERFORM_ACTION') {
                        this.handlePerformAction(response);
                    } else if(name === 'SHOPACTIONS') {
                        this.handleShopActionResponse(response);
                    } else {
                        this.showScreen(response);
                    }
                } else {
                    this.state.showError = true;
                    this.state.showLoading = false;
                    this.handleAccessibilityFocus();
                }
            }).catch(() => {
                this.state.showError = true;
                this.state.showLoading = false;
                this.handleAccessibilityFocus();
            }).finally(() => {
                // this.state.showLoading = false;
                clearTimeout(id);
            });
        }
    }

    handleBack(pageName, backIcon) {
        this.publishTracking(get(backIcon, 'action.trackingList', []));
        const pageNameKeys = Object.keys(this.pageMapping);

        pageNameKeys.forEach(key => {
            if (this.pageMapping[key] === pageName) {
                const index = pageNameKeys.indexOf(key);
                const prevKey = (index === 1 || index === 2 ) ? pageNameKeys[0] : pageNameKeys[index - 1];
                this.state.visiblePage = this.pageMapping[prevKey];
                this.state.showError = false;
                this.state.showLoading = false;
            }
        });
        this.handleAccessibilityFocus();
    }

    handleClose(closeAction, event) {
        this.publishTracking(get(closeAction, 'trackingList', []));
        if (this.isSpoke) {
            pubsub
            .channel(this.channelId)
            .emit(('VAS_DISMISS_SPOKE'));
        } else {
            // In case of Nudge close click, we need to do _sp tag linking
            if (event) {
                if(event.stopPropagation){
                  event.stopPropagation();
                }
                if(event.preventDefault){
                  event.preventDefault();
                }
            }
            this.state.showLoading = true;
            doRedirection(closeAction, this.trksid, this.vasContainerElem);
        }
    }

    handlePageMapping(model) {
        // from BE if we get the response without expecting keys then we need to show the error screen
        let isModelValid = false;
        Object.keys(this.pageMapping).forEach(key => {
            if (model[key]) {
                isModelValid = true;
                const pageName = this.pageMapping[key];
                this.state.visiblePage = pageName;
                this.state[pageName] = model;
            }
        });

        if(isModelValid === false) {
            this.state.showError = true;
        }
    }

    handleDrawerClose() {
        setElementFocus("#vas-header-close-icon", this.targetElId);
    }

    handleAccessibilityFocus() {
        setTimeout(
            () => {
                this.refreshKeyboardTrap(this.channelId);
                this.setScreenDefaultFocus();
            },
            100
        );
    }

    setScreenDefaultFocus () {
        const focusElementQuery = this.state.showError ? '.error-screen__content p > span:first-child' : this.getDefaultFocusElement();
        setElementFocus(focusElementQuery, this.targetElId);
    }

    getDefaultFocusElement() {
        const visiblePage = get(this,'state.visiblePage', '');
        if(visiblePage === 'installer' && this.isMobile) {
            return  '.lightbox-dialog__close'
        } else if (visiblePage === 'introduction') {
            return '#vas-header-close-icon';
        }
        return '.header-title > span:first-child';
    }

    refreshKeyboardTrap (channelId) {
        pubsub.channel(channelId).emit('VAS_SCREEN_CONTROL_REFRESH');
    }

    publishTracking(trackingList) {
        if (trackingList && trackingList.length > 0) {
            pubsub.channel(this.channelId).emit('tracking', trackingList);
        }
    }

    handlePerformAction(response) {
        const vasData = get(response, 'VAS_DATA', {});
        const vasDataItems = get(vasData, 'dataItems', []);
        if(vasDataItems.length) {
            const viewItemResponse = {
                vasContent: get(this, `state.summary.VAS_DATA.content`, {}),
                vasSelectionParams: [],
                vasSelectionDisplayParams: [],
                vasData: vasData
            };
            vasDataItems.forEach(vasDataItem => {
                if (vasDataItem) {
                    viewItemResponse.vasSelectionParams.push(vasDataItem);
                    viewItemResponse.vasSelectionDisplayParams.push({
                        "serviceType": vasDataItem.serviceType,
                        "serviceValue": vasDataItem.serviceValue,
                        "shippingInfo": {
                            "shippingZipCode": vasDataItem.hubPostalCode || '',
                            "shipToCountryCode": vasDataItem.hubCountryCode || ''
                        },
                        "vasIntentId": vasDataItem.intentId,
                        'defaultShippingInfo': {
                            'shippingZipCode': vasDataItem.userPostalCode,
                            'shipToCountryCode': vasDataItem.userCountryCode
                        },
                    });
                }
            });

            pubsub.channel(this.channelId).emit('VAS_CLOSE_SPOKE', viewItemResponse);
        } else {
            this.state.showError = true;
        }
    }

    // This callback called only for shopaction success case
    handleShopActionResponse(response) {
        const successRedirectionUrl = get(response, 'meta.screenFlowDestination.URL', '');
        if (successRedirectionUrl) {
            doRedirectionByUrl(successRedirectionUrl, this.trksid, this.vasContainerElem);
        } else {
            this.showScreen(response.modules);
        }
    }

    showScreen(response){
        this.handlePageMapping(response);
        this.state.showLoading = false;
        this.handleAccessibilityFocus();
    }
};